
import { Component, Prop, Mixins } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
import comunas from "@/helpers/comunas.json";
import countries from "@/helpers/countries.json";

interface registerData {
  name: string;
  father_last_name: string;
  mother_last_name: string;
  nationality: any;
  document_type: string;
  document_id: string;
  district: any;
  street: string;
  street_number: string;
  hint: string;
  email: string;
  phone: string;
  password: string;
  password_confirm: string;
  facebook: any | null;
  instagram: any | null;
  twitter: any | null;
}

@Component
export default class RegisterForm extends Mixins(FormValidator) {
  @Prop({ default: false }) readonly quick_login!: boolean;
  options_document: object[] = [
    { label: "DNI", value: "0" },
    { label: "RUT", value: "1" },
    { label: "Pasaporte", value: "2" }
  ];

  options_district: any = comunas;
  options_nationality: any = countries;
  options_street: any = [];
  street_loading = false;
  street_search_empty = false;
  chilean = true;

  protected formData: registerData = {
    name: "",
    father_last_name: "",
    mother_last_name: "",
    nationality: {
      value: 47,
      code: "CL",
      country_en: "Chile",
      country_es: "Chile",
      label: "Chilena"
    },
    document_type: "1",
    document_id: "",
    district: "",
    street: "",
    street_number: "",
    hint: "",
    email: "",
    phone: "",
    password: "",
    password_confirm: "",
    facebook: null,
    instagram: null,
    twitter: null
  };

  private registerAttemp(): void {
    this.$axios
      .post("personas/registro", {
        nombre: this.formData.name,
        apellido_paterno: this.formData.father_last_name,
        apellido_materno: this.formData.mother_last_name,
        email: this.formData.email,
        password: this.formData.password,
        password_confirmacion: this.formData.password_confirm,
        telefono: this.formData.phone,
        tipo_documento: Number(this.formData.document_type),
        rut: this.formData.document_id,
        id_pais: this.formData.nationality.value,
        //borrar una vez que se arregle el endpoint
        facebook: this.formData.facebook,
        instagram: this.formData.instagram,
        twitter: this.formData.twitter,
        direccion: {
          id_comuna: this.formData.district.value,
          calle: this.formData.street,
          numero: this.formData.street_number,
          aclaratoria: this.formData.hint,
          //borrar una vez se arregle el endpoint
          tipo: 0
        }
      })
      .then(response => {
        this.$store.dispatch("login", response.data);
        this.redirectHome();
      })
      .catch(error => {
        this.$emit("error", error.response.data.error.message);
      });
  }

  private checkIfChilean() {
    if (this.formData.nationality.value === 47) {
      this.chilean = true;
      this.formData.document_type = "1";
    } else {
      this.chilean = false;
    }
  }
  private clearStreet(): void {
    if (this.formData.district.label == this.comuna_cliente) {
      this.formData.street = "";
    }
  }
  private redirectHome(): void {
    if (this.quick_login) {
      this.$emit("logged");
      return;
    }
    this.$router.push({ name: "home" });
  }

  private rutFormat() {
    if (this.formData.document_type != "1") {
      return true;
    }
    this.formData.document_id = this.formData.document_id
      .replace(/[.-]/g, "")
      .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
  }

  private async filterStreet(val: string, update: any, abort: any) {
    this.street_loading = true;
    if (val === "") {
      update(() => {
        this.options_street = [];
      });
      this.street_loading = false;
      this.street_search_empty = true;
      return;
    }
    await this.loadStreets(val);

    setTimeout(() => {
      update(() => {});
    }, 200);

    return;
  }

  private async loadStreets(val: string) {
    const value = val.toLowerCase();
    let streets = await this.apiStreets(val);
    if (streets.err) {
      this.options_street = [];
      return;
    }
    this.street_loading = false;
    this.street_search_empty = false;
    this.options_street = streets;
  }

  private abortFilterFn() {}

  private filterDistrict(val: string, update: any) {
    if (val === "" || val == undefined) {
      update(() => {
        this.options_district = comunas;
      });
      return;
    }

    update(() => {
      let value = val.toLowerCase();
      this.options_district = (comunas as any).filter((comuna: any) => {
        return (
          comuna.label.toLowerCase().indexOf(value) > -1 ||
          comuna.label === this.formData.district.label
        );
      });
    });
  }

  private filterNationality(val: string, update: any) {
    if (val === "") {
      update(() => {
        this.options_nationality = countries;
      });
      return;
    }

    update(() => {
      const value = val.toLowerCase();
      this.options_nationality = (countries as any).filter((country: any) => {
        let filtered =
          country.label.toLowerCase().indexOf(value) > -1 ||
          country.label === this.formData.nationality.label;
        return filtered;
      });
    });
  }
}
